<template>
  <div class="d-inline-block mr-1">
    <b-badge pill variant="light-dark" :id="component_id">
      <letter-component v-for="(letter) in word.letters" :key="`${letter.start_time}`" :letter="letter" />
    </b-badge>
    <b-popover :target="component_id" triggers="hover" placement="bottomleft">

      <div class="phone-hint p-2">
        <!-- phones -->
        <div>
          <table class="hint-table">
            <thead>
              <tr>
                <th>Word</th>
                <th>Phone</th>
                <th>Score</th>
                <th>Sample</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(phone, index) in word.phones" :key="phone">
                <th :rowspan="word.phones.length" v-if="index == 0">
                  <h4 class="mr-3">{{ word.word }}</h4>
                  <audio-player v-if="word.sample_audio" :src="word.sample_audio" />
                </th>
                <td style="color: rgb(221, 56, 29);">{{ phone.phone_ipa }}</td>
                <td style="color: rgb(221, 56, 29);">{{ phone.score | float_rounded(2) }}</td>
                <td><audio-player :src="phone.sample_audio"/></td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </b-popover>
  </div>
</template>
<script>
import { createUid } from '@core/utils/index';
export default {
	components: {
		AudioPlayer: () => import('./AudioPlayer'),
		LetterComponent: () => import('./LetterComponent')
	},
	props: {
		word: {
			type: Object,
      default: () => { return {}; },
		}
	},
	data() {
		return {
      component_id: createUid('word'),
    };
	},
	computed: {
		color() {
			let color = null;
			if (this.word.score < 0.6) {
				color = 'danger';
			} else if (this.word.score <= 0.8) {
				color = 'warning';
			} else {
				color = 'primary';
			}
			return color;
		}
	},
}
</script>
<style lang="scss">
.popover {
  max-width: 100% !important;
}
.hint-table {
  white-space: nowrap;
  margin: 0;
  width: 100%;
  text-align: center;

  tr:first-child th,
  tr:first-child td {
    border-top: none;
	}

	tr th {
		padding: 15px 10px;
    border-bottom: 1px solid #ededed;
	}
	tr td {
		padding: 15px 10px;
    border-top: 1px solid #ededed;
    border-left: 1px solid #ededed;
	}
}

.phone-hint {
	max-height: 350px;
	overflow-y: auto;
}

.phone-hint::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.phone-hint::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.phone-hint::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #ccc;
}
</style>
